//(function (root, factory) {
//  if (typeof define === 'function' && define.amd) {
//    // AMD. Register as an anonymous module.
//    define([], function () {
//      return root.returnExportsGlobal = factory()
//    })
//  } else if (typeof exports === 'object') {
//    // Node. Does not work with strict CommonJS, but
//    // only CommonJS-like enviroments that support module.exports,
//    // like Node.
//    module.exports = factory()
//  } else {
//    root['Chartist.plugins.ctAccessibility'] = factory()
//  }
//}(this, function () {
/**
 * Chartist.js plugin that generates visually hidden tables for better
 * accessibility. It's also possible to initialize a Chart with data from an
 * existing table.
 * 
 */
/* global Chartist */
(function (window, document, Chartist) {
  'use strict';
  // A simple recursive DOM string builder
  function Element(name, attrs, parent) {
    return {
      elem: function (name, attrs) {
        var e = Element(name, attrs, this);
        this.children.push(e);
        return e
      },
      children: [],
      name: name,
      _attrs: attrs || {},
      _parent: parent,
      parent: function () {
        return this._parent
      },
      attrs: function (attrs) {
        this._attrs = attrs;
        return this
      },
      text: function (text, after) {
        if (after) {
          this._textAfter = text
        } else {
          this._textBefore = text
        }
        return this
      },
      toString: function () {
        var attrs = Object.keys(this._attrs).filter(function (attrName) {
          return this._attrs[attrName] || this._attrs[attrName] === 0
        }.bind(this)).map(function (attrName) {
          return [
            attrName,
            '="',
            this._attrs[attrName],
            '"'
          ].join('')
        }.bind(this)).join(' ');
        return [
          '<',
          this.name,
          attrs ? ' ' + attrs : '',
          '>',
          this._textBefore
        ].concat(this.children.map(function (child) {
          return child.toString()
        })).concat([
          this._textAfter,
          '</',
          this.name,
          '>'
        ]).join('')
      }
    }
  }
  var defaultOptions = {
    className: 'ftb_chart__table',
    caption: 'A graphical chart',
    seriesHeader: 'Series name',
    valueTransform: Chartist.noop,
    summary: undefined,
    transpose: undefined,
    elementId: function () {
      return 'ct-accessibility-table-' + +new Date()
    }
  };
  Chartist.plugins = Chartist.plugins || {};
  Chartist.plugins.ctDataTable = function (options) {
    options = Chartist.extend({}, defaultOptions, options);
    return function ctDataTable(chart) {
      var elementId = typeof options.elementId === 'function' ? options.elementId() : options.elementId;
      chart.on('created', function (data) {
        var todraw = [];
        var chartElement = data.svg._node.parentNode;
        var containerElement = chartElement.parentNode;
        var previousElement = containerElement.querySelector('#' + elementId);
        if (previousElement) {
          containerElement.removeChild(previousElement)
        }
        // As we are now compensating the SVG graphic with the chart with an
        // accessibility table, we hide it for ARIA
        // data.svg.attr({
        // 'aria-hidden': 'true'
        // });
        // Create wrapper element
        // var tableWrapper = document.createElement('div');
        var element = Element('div', {
          class: options.className,
          id: elementId
        });
        // Create table body, for a caption add: ".elem('caption').text(options.caption)"
        var tTable = element.elem('table', {
          summary: options.summary,
          class: 'ftb-table ftb-table--chart'
        });
        var tColgroup = tTable.elem('colgroup').elem('col', { class: 'first' });
        var tBody = tTable.elem('tbody');
        var firstRow = tBody.elem('tr');
        var normalizedData = Chartist.getDataArray(chart.data, chart.optionsProvider.getCurrentOptions().reverseData);
        // Assemble the table either for single (e.g. pie charts) or multidimensional array
        if (normalizedData[0].constructor !== Array) {
          if (options.transpose) {
            chart.data.series.forEach(function (series, index) {
              if (chart.data.labels[index].length > 0) {
                var row = tBody.elem('tr');
                row.elem('th', {
                  scope: 'row',
                  role: 'rowheader'
                }).text(chart.data.labels[index]);
                row.elem('td').text(options.valueTransform(chart.data.series[index]))
              }
            })
          } else {
            // First render the column headers with our chart labels
            for (var i = 0; i < chart.data.labels.length; i += 1) {
              var text = chart.data.labels[i];
              if (text.length > 0) {
                firstRow.elem('th', {
                  scope: 'col',
                  role: 'columnheader'
                }).text(text);
                todraw[i] = 1
              } else {
                todraw[i] = 0
              }
            }
            ;
            var row = tBody.elem('tr');
            // Add all data fields of our pie chart to the row
            for (var i = 0; i < normalizedData.length; i += 1) {
              if (todraw[i]) {
                row.elem('td').text(options.valueTransform(normalizedData[i]))
              }
            }
          }
        } else {
          if (options.transpose) {
            /*
             *                    1. Quartal  2. Quartal  3. Quartal  4. Quartal
             * Selbstdisziplin    20          11          7           17
             * Offenheit          5           13          6           15
             * Selbstkenntnis     16          8           4           4
             * Selbstvertrauen    14          8           6           12
             * Handlungskompetenz 5           8           6           11
             * Teamfähigkeit      12          7           4           4
             */
            //            transpose the normalized data matrix
            var normalizedDataTrans = normalizedData[0].map(function (col, i) {
              return normalizedData.map(function (row) {
                return row[i]
              })
            });
            // Add column headers inclusing the series column header for the row
            // headers
            [options.seriesHeader].concat(chart.data.series).forEach(function (series, index) {
              var seriesName = series.name || [].join('');
              firstRow.elem('th', {
                scope: 'col',
                role: 'columnheader'
              }).text(seriesName)
            });
            // Add all data rows including their row headers
            chart.data.labels.forEach(function (label, index) {
              var row = tBody.elem('tr');
              row.elem('th', {
                scope: 'row',
                role: 'rowheader'
              }).text(label);
              if (normalizedDataTrans) {
                normalizedDataTrans[index].forEach(function (dataValue) {
                  row.elem('td').text(options.valueTransform(dataValue))
                })
              }
            })
          } else {
            // Add column headers inclusing the series column header for the row
            // headers
            [options.seriesHeader].concat(chart.data.labels).forEach(function (text) {
              firstRow.elem('th', {
                scope: 'col',
                role: 'columnheader'
              }).text(text)
            });
            // Add all data rows including their row headers
            chart.data.series.forEach(function (series, index) {
              var seriesName = series.name || [
                index + 1,
                '. Series'
              ].join('');
              var row = tBody.elem('tr');
              row.elem('th', {
                scope: 'row',
                role: 'rowheader'
              }).text(seriesName);
              if (normalizedData) {
                normalizedData[index].forEach(function (dataValue) {
                  row.elem('td').text(options.valueTransform(dataValue))
                })
              }
            })
          }
        }
        // Update invisible table in DOM and update table element with newly created table
        containerElement.appendChild(new DOMParser().parseFromString(element.toString(), 'text/html').getElementById(elementId))
      })
    }
  }
}(window, document, Chartist))